<script lang="ts">
  import { page } from '$app/stores';
  import type {
    AllCategories,
    Categories,
    Category,
  } from '$lib/contentful/models/blog';
  import { toKebabCase } from '$lib/utilities/to-kebab-case';

  let className = '';
  export { className as class };
  export let categories: Categories | AllCategories;
  export let publishDate: string | undefined = undefined;

  $: isActive = (category: string) => $page?.params?.category === category;

  const categoryLink = (category: Category) => {
    if (category === 'All') return '/blog';
    return `/blog/categories/${category}`;
  };
</script>

<div class="flex gap-2 items-center flex-wrap {className}">
  {#each categories as category}
    <a
      data-sveltekit-preload-data
      class="category {toKebabCase(category)}"
      class:active={isActive(category)}
      href={categoryLink(category)}>{category}</a
    >
  {/each}
  {#if publishDate}
    <p>{new Date(publishDate).toLocaleDateString()}</p>
  {/if}
</div>

<style lang="postcss">
  .category {
    @apply px-2 rounded-full flex whitespace-nowrap hover:text-white transition-colors;
  }

  .announcements {
    @apply bg-indigo-100 text-indigo-400 hover:bg-indigo-400;
  }

  .announcements.active {
    @apply bg-indigo-400;
  }

  .using-temporal {
    @apply bg-yellow-100 text-yellow-900 hover:bg-yellow-700;
  }
  .using-temporal.active {
    @apply bg-yellow-700;
  }

  .distributed-systems {
    @apply bg-red-100 text-red-700 hover:bg-red-700;
  }

  .distributed-systems.active {
    @apply bg-red-700;
  }

  .community {
    @apply bg-green-100 text-green-700 hover:bg-green-700;
  }

  .community.active {
    @apply bg-green-700;
  }

  .engineering {
    @apply bg-pink-100 text-pink-700 hover:bg-pink-700;
  }

  .engineering.active {
    @apply bg-pink-700;
  }

  .life-at-temporal {
    @apply bg-blue-gray-100 text-blue-gray-700 hover:bg-blue-gray-700;
  }

  .life-at-temporal.active {
    @apply bg-blue-gray-700;
  }

  .active {
    @apply text-white;
  }

  .all {
    @apply hover:bg-white hover:text-space-black;
  }
</style>
